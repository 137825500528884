<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Create'" :selected="true">
        <departments-create />
      </tab>
      <tab :name="'Retrieve'">
        <departments-retrieve />
      </tab>
      <tab :name="'Retrieve List'">
        <departments-retrieve-list />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import DepartmentsCreate from './DepartmentsCreate.md'
import DepartmentsRetrieve from './DepartmentsRetrieve.md'
import DepartmentsRetrieveList from './DepartmentsRetrieveList.md'

export default {
  name: 'DepartmentsPage',
  components: {
    Tabs,
    Tab,
    DepartmentsCreate,
    DepartmentsRetrieve,
    DepartmentsRetrieveList
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
