export default {
  state: () => ({
    isLoggedIn: false,
    requiresReload: false,
    firstName: '',
    lastName: '',
    email: ''
  }),
  mutations: {
    setLoggedIn (state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn
    },
    setRequiresReload (state, value) {
      state.requiresReload = value
    },
    setFirstName (state, value) {
      state.firstName = value
    },
    setLastName (state, value) {
      state.lastName = value
    },
    setEmail (state, value) {
      state.email = value
    }
  },
  getters: {
    isLoggedIn (state) {
      return state.isLoggedIn
    },
    requiresReload (state) {
      return state.requiresReload
    },
    firstName (state) {
      return state.firstName
    },
    lastName (state) {
      return state.lastName
    },
    email (state) {
      return state.email
    }
  }
}
