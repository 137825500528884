<template>
  <section class="content">
    <p>
      This help site includes documentation to guide your use of the Cloud-based platform, the mobile application and managing your integrations.
    </p>
    <p>
      If you need help or have questions, contact <a href="mailto:support@aligntech.co">support@aligntech.co</a> or give us a call at 800.580.3114.<br />Phone support is available Monday–Friday, 6 AM–5 PM Mountain Time (excluding holidays).
    </p>
  </section>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>
