<template>
  <section class="content login">
    <h1>Welcome to the Enterprise Asset Management Help Portal</h1>
    <p>Please login using your Platform credentials.  If you do not yet have a login, or are otherwise experiencing any issues logging in, please <a href="https://www.aligntech.co/support-customer-services">contact our support team</a>.  We're here to help!</p>
    <form method="post" @submit="onSubmit">
      <p class="error" v-if="isValidationError">
        Invalid email or password
      </p>
      <div>
        <div>
          <label for="email">Email</label>
        </div>
        <div>
          <input
            type="text"
            name="email"
            autocomplete="off"
            autofocus
            v-model="email"
            placeholder="user@example.com"
            size="30"
            required
          >
        </div>
      </div>
      <div>
        <div>
          <label for="pwd">Password</label>
        </div>
        <div>
          <input
            type="password"
            autocomplete="off"
            placeholder="******"
            size="30"
            v-model="password"
            name="password"
            required
          >
        </div>
      </div>
      <button type="submit" class="btn">
        Log In
        <span
          v-if="processing"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        >
        </span>
      </button>
    </form>
  </section>
</template>

<script>
import axios from 'axios'
import { clearLocalStorage } from '../../lib/util.js'

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      processing: false,
      isValidationError: false
    }
  },
  methods: {
    async onSubmit (e) {
      e.preventDefault()
      const params = new URLSearchParams()
      params.append('email', this.email)
      params.append('pass', this.password)
      const headerConfig = {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
      }
      try {
        this.processing = true
        this.isValidationError = false
        const { status } = await axios.post('/api/login', params, headerConfig)
        if (status !== 201) {
          throw new Error('invalid')
        }
        clearLocalStorage()
        let redirectUrl = this.$route.query.redirect_url
        delete this.$route.query.redirect_url
        if (redirectUrl) {
          const query = Object.entries(this.$route.query)
          query.forEach(x => {
            redirectUrl += `&${x[0]}=${x[1]}`
          })
          window.location.href = redirectUrl
        } else {
          window.location.href = '/'
        }
      } catch (error) {
        this.isValidationError = true
      } finally {
        this.processing = false
      }
    }
  }
}
</script>
<style scoped>
  p.error {
    color: red;
    font-weight: bold;
  }
  .content.login {
    margin-left: 0px;
    min-height: 500px;
  }
  .content.login h1 {
    font-size: 1.5em;
  }
  .content.login p, .content.login form {
    max-width: 28em;
  }
  @media (min-width: 768px) {
    .content.login h1 {
      font-size: 2.5em;
    }
  }
  .content.login input {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    width: 100%;
  }
  @media (min-width: 1280px) {
    .content.login input {
      width: unset;
    }
  }
  .btn {
    border: 1px solid #ced4da;
    margin-top: 0.75em;
    background-color: #EFEFEF;
    font-size: 15px;
  }
  .btn:hover {
    background-color: rgb(190, 190, 190);
  }
</style>
