<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Retrieve'" :selected="true">
        <service-request-retrieve />
      </tab>
      <tab :name="'Retrieve Request'">
        <service-request-retrieve-request />
      </tab>
    </tabs>
  </section>
</template>
<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import ServiceRequestRetrieve from './ServiceRequestRetrieve.md'
import ServiceRequestRetrieveRequest from './ServiceRequestRetrieveRequest.md'

export default {
  name: 'ServiceRequestPage',
  components: {
    Tabs,
    Tab,
    ServiceRequestRetrieve,
    ServiceRequestRetrieveRequest
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
