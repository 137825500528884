<template>
  <section
    role="tabpanel"
    class="tabs-component-panel active show"
    v-show="isActive">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    name: {
      required: true
    },
    selected: {
      default: false
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    href () {
      return '#' + this.name.toLowerCase().replace(/ /g, '-')
    },
    id () {
      return 'tab_' + this.name.toLowerCase().replace(/ /g, '-')
    }
  },
  mounted () {
    this.isActive = this.selected
  }
}
</script>
