<template>
  <section class="content">
    <h1 class="text-center">
      Thank you for your submission.
    </h1>
  </section>
</template>

<script>
export default {
  name: 'SupportThanksPage'
}
</script>
