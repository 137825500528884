<template>
  <section class="content">
    <cloud-getting-started />
    <cloud-backoffice />
    <cloud-job-site-portal />
    <cloud-warehouse />
  </section>
</template>
<script>
import CloudGettingStarted from './CloudGettingStarted.md'
import CloudBackoffice from './CloudBackoffice.md'
import CloudJobSitePortal from './CloudJobSitePortal.md'
import CloudWarehouse from './CloudWarehouse.md'

export default {
  name: 'CloudUserGuidePage',
  components: {
    CloudGettingStarted,
    CloudBackoffice,
    CloudJobSitePortal,
    CloudWarehouse
  }
}
</script>
