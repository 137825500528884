<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Retrieve'" :selected="true">
        <work-order-charges-retrieve />
      </tab>
    </tabs>
  </section>
</template>
<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import WorkOrderChargesRetrieve from './WorkOrderChargesRetrieve.md'

export default {
  name: 'WorkOrderChargesPage',
  components: {
    Tabs,
    Tab,
    WorkOrderChargesRetrieve
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
