export default {
  state: () => ({
    initialLoading: false
  }),
  mutations: {
    setInitialLoading (state, flag) {
      state.initialLoading = flag
    }
  },
  getters: {
    initialLoading (state) {
      return state.initialLoading
    }
  }
}
