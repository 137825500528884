<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Create'" :selected="true">
        <entities-create />
      </tab>
      <tab :name="'Retrieve'">
        <entities-retrieve />
      </tab>
      <tab :name="'Retrieve List'">
        <entities-retrieve-list />
      </tab>
      <tab :name="'Update'">
        <entities-update />
      </tab>
      <tab :name="'Delete'">
        <entities-delete />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import EntitiesCreate from './EntitiesCreate.md'
import EntitiesRetrieve from './EntitiesRetrieve.md'
import EntitiesRetrieveList from './EntitiesRetrieveList.md'
import EntitiesUpdate from './EntitiesUpdate.md'
import EntitiesDelete from './EntitiesDelete.md'

export default {
  name: 'EntitiesPage',
  components: {
    Tabs,
    Tab,
    EntitiesCreate,
    EntitiesRetrieve,
    EntitiesRetrieveList,
    EntitiesUpdate,
    EntitiesDelete
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
