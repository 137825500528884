<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Create'" :selected="true">
        <cost-codes-create />
      </tab>
      <tab :name="'Retrieve'">
        <cost-codes-retrieve />
      </tab>
      <tab :name="'Retrieve List'">
        <cost-codes-retrieve-list />
      </tab>
      <tab :name="'Update'">
        <cost-codes-update />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import CostCodesCreate from './CostCodesCreate.md'
import CostCodesRetrieve from './CostCodesRetrieve.md'
import CostCodesRetrieveList from './CostCodesRetrieveList.md'
import CostCodesUpdate from './CostCodesUpdate.md'

export default {
  name: 'CostCodesPage',
  components: {
    Tabs,
    Tab,
    CostCodesCreate,
    CostCodesRetrieve,
    CostCodesRetrieveList,
    CostCodesUpdate
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
