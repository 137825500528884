<template>
  <section class="content">
    <mobile-toolwatch-field />
    <mobile-toolwatch-warehouse />
  </section>
</template>

<script>
import MobileToolwatchField from './MobileToolwatchField.md'
import MobileToolwatchWarehouse from './MobileToolwatchWarehouse.md'

export default {
  name: 'MobileUserGuidePage',
  components: {
    MobileToolwatchField,
    MobileToolwatchWarehouse
  }
}
</script>
