<template>
  <div id="app">
    <div id="loading__container" v-if="initialLoading">
      <img src="./assets/images/dozer.png" alt="initial loading">
    </div>
    <div class="banners-container">
      <div class="refresh-container" v-if="requiresReload">
        <span>Your session has expired - <a class="reload-link" @click="reload">Click here</a> or press F5 to refresh.</span>
      </div>
    </div>
    <section class="wrapper">
      <tw-header v-if="!initialLoading"/>
      <tw-sidebar
        v-if="isLoggedIn  && !$route.meta.hideNavbar"
        :navigations="navigations"
        :isRoute="isRoute"
      />
      <router-view></router-view>
      <tw-footer v-if="!initialLoading"/>
    </section>
  </div>
</template>

<script>
import TwHeader from '@/components/documentation/TwHeader.vue'
import TwSidebar from '@/components/documentation/TwSidebar.vue'
import TwFooter from '@/components/documentation/TwFooter.vue'
import { getGroupedNavigations } from '@/lib/util.js'
import routes from '@/router/routes'

export default {
  name: 'App',
  components: {
    TwHeader,
    TwSidebar,
    TwFooter
  },
  data () {
    return {
      isRoute: true,
      navigations: []
    }
  },
  updated () {
    const urlParams = new URLSearchParams(window.location.search)
    const scrollToElement = urlParams.get('scrollTo')
    if (scrollToElement) {
      const targetElement = document.getElementById(scrollToElement)
      if (targetElement) {
        targetElement.scrollIntoView({ behaviour: 'smooth' })
      }
    }
  },
  methods: {
    reload () {
      window.location.reload()
    }
  },
  watch: {
    $route: {
      handler: function (value) {
        const path = value.path.split('/')[1]
        const childPath = value.path.split('/')[2]
        if (path == '') {
          this.navigations = getGroupedNavigations(routes[1].children)
          this.isRoute = true
        } else if (path == 'Resources') {
          if (childPath == '') {
            this.navigations = getGroupedNavigations(routes[1].children[1].children)
            this.isRoute = true
          } else {
            const selectedRoute = routes[1].children[1].children.find(route => childPath == route.path)
            this.isRoute = !selectedRoute?.isHashTag
            this.navigations = !selectedRoute ? [] : getGroupedNavigations(selectedRoute.children)
          }
        } else if (path == 'Cloud') {
          this.navigations = getGroupedNavigations(routes[1].children[2].children)
          this.isRoute = false
        } else if (path == 'Mobile') {
          this.navigations = getGroupedNavigations(routes[1].children[3].children)
          this.isRoute = false
        }
      },
      immediate: true
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    initialLoading () {
      return this.$store.getters.initialLoading
    },
    requiresReload () {
      return this.$store.getters.requiresReload
    }
  }
}
</script>
<style>
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  #loading__container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  #loading__container img{
    animation: zoom-in-zoom-out 1s ease-in infinite;
  }
  .banners-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10000;
  }
  .refresh-container {
    left: 0;
    top: 0;
    width: 100%;
    background-color: red;
    z-index: 10000;
    text-align: center;
    color: white;
    font-weight: bold;
  }
  .reload-link {
    text-decoration: underline!important;
  }
  .impersonation-container {
    left: 0;
    top: 0;
    width: 100%;
    background-color: red;
    z-index: 10000;
    color: white;
    font-weight: bold;
    padding-left:10px;
  }
  .sandbox-container {
    left: 0;
    top: 0;
    width: 100%;
    background-color: yellow;
    z-index: 10000;
    color: black;
    font-weight: bold;
    padding-left:10px;
  }
  .reload-link {
    text-decoration: underline!important;
  }
</style>
