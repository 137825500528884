import axios from 'axios'
import store from '../store/index'

export const getGroupedNavigations = (routes) => {
  const groups = []
  const navsByGroup = []
  routes.forEach(route => {
    if (!route.group || groups.some(({ group }) => group === route.group)) return
    groups.push({
      group: route.group,
      groupName: route.groupName,
      link: route.linkRoute
    })
  })
  groups.forEach(({ group, groupName, link }) => {
    const obj = {}
    obj.group = group
    obj.groupName = groupName
    obj.link = link
    obj.items = []
    routes.forEach(route => {
      if (route.group == group) {
        if (route.name) {
          obj.items.push(route)
        } else if (route.children?.length) {
          obj.items.push(getGroupedNavigations(route.children))
        }
      }
    })
    navsByGroup.push(obj)
  })
  return navsByGroup
}

export const isAuthenticated = async () => {
  if (store.getters.isLoggedIn) return true

  try {
    const { data, status } = await axios.get('/api/session/self/')
    if (status === 200 && data.OrgId) {
      localStorage.setItem('SessionId', data.SessionId)
      store.commit('setEmail', data.UserName)
      store.commit('setFirstName', data.FirstName)
      store.commit('setLastName', data.LastName)
      store.commit('setLoggedIn', true)
      return true
    } else {
      throw new Error('invalid')
    }
  } catch (error) {
    return false
  }
}

export function clearLocalStorage () {
  localStorage.removeItem('SessionId')
  store.commit('setFirstName', '')
  store.commit('setLastName', '')
  store.commit('setEmail', '')
}
