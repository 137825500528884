<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Create'" :selected="true">
        <models-create />
      </tab>
      <tab :name="'Retrieve'">
        <models-retrieve />
      </tab>
      <tab :name="'Retrieve List'">
        <models-retrieve-list />
      </tab>
      <tab :name="'Retrieve Image'">
        <models-retrieve-image />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import ModelsCreate from './ModelsCreate.md'
import ModelsRetrieve from './ModelsRetrieve.md'
import ModelsRetrieveList from './ModelsRetrieveList.md'
import ModelsRetrieveImage from './ModelsRetrieveImage.md'

export default {
  name: 'ModelsPage',
  components: {
    Tabs,
    Tab,
    ModelsCreate,
    ModelsRetrieve,
    ModelsRetrieveList,
    ModelsRetrieveImage
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
