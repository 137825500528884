<template>
  <article class="subtitle">
    <template v-for="(item, index) in breadCrumbs">
      <span :key="`${item.name}_${index}`">
        <template v-if="!index ? true : breadCrumbs.length != index + 1">
          <router-link
            :to="{ name: item.name }"
          >{{getBreadCrumb(item.meta.breadcrumb)}}</router-link>
        </template>
        <template v-else>
          {{getBreadCrumb(item.meta.breadcrumb)}}
        </template>
      </span>
      <span
        :key="`arrow_${index}`"
        v-if="breadCrumbs.length != index + 1"
      >></span>
    </template>
  </article>
</template>

<script>
export default {
  name: 'BreadCrumb',
  methods: {
    getBreadCrumb (bredcrumb) {
      if (typeof bredcrumb === 'object') {
        return bredcrumb.template(this.id)
      } else return bredcrumb
    }
  },
  computed: {
    breadCrumbs () {
      return this.$breadcrumbs
    },
    id () {
      return this.$route.params.id
    }
  }
}
</script>
<style scoped>
article.subtitle {
  margin: 0;
  padding: 10px 5px 10px 5px;
  background-color: transparent;
  border-bottom: solid thin #000;
}
.subtitle :link, .subtitle :visited {
  text-decoration: underline;
}
.subtitle > span {
  font-size: 14px;
  margin-left: 10px;
}
.subtitle span, .subtitle span a {
  color: #333;
}
@media (min-width: 1200px) {
  article.subtitle {
    padding: 12px 24px;
  }
}
</style>
