import store from './store'
import Vue from 'vue'
import router from './router'
import App from './App.vue'
import VueBreadCrumbs from 'vue-breadcrumbs'
import cookie from 'cookie'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import vuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/toolwatch.css'

const cookies = cookie.parse(document.cookie || '')
axios.defaults.headers.common['X-TW-CSRF'] = cookies['tw.cloud.csrf']
Vue.use(VueBreadCrumbs, {
  registerComponent: false
})
Vue.use(Vuelidate)
Vue.use(vuelidateErrorExtractor, {
  template: templates.singleErrorExtractor.foundation6,
  messages: {
    required: '{attribute} is required',
    email: '{attribute} is not valid'
  }
})

Vue.component('FormWrapper', templates.FormWrapper)
Vue.component('FormErrors', templates.multiErrorExtractor.foundation6)

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
