<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Create'" :selected="true">
        <purchase-orders-create />
      </tab>
      <tab :name="'Retrieve'">
        <purchase-orders-retrieve />
      </tab>
      <tab :name="'Retrieve List'">
        <purchase-orders-retrieve-list />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import PurchaseOrdersCreate from './PurchaseOrdersCreate.md'
import PurchaseOrdersRetrieve from './PurchaseOrdersRetrieve.md'
import PurchaseOrdersRetrieveList from './PurchaseOrdersRetrieveList.md'

export default {
  name: 'PurchaseOrdersPage',
  components: {
    Tabs,
    Tab,
    PurchaseOrdersCreate,
    PurchaseOrdersRetrieve,
    PurchaseOrdersRetrieveList
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
