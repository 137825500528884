<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Export'" :selected="true">
        <billing-request />
        <billing-response />
        <billing-data-table />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import BillingRequest from './BillingRequest.md'
import BillingResponse from './BillingResponse.md'
import BillingDataTable from './BillingDataTable.md'

export default {
  name: 'BillingPage',
  components: {
    Tabs,
    Tab,
    BillingRequest,
    BillingResponse,
    BillingDataTable
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
