<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Create'" :selected="true">
        <status-codes-create />
      </tab>
      <tab :name="'Retrieve'">
        <status-codes-retrieve />
      </tab>
      <tab :name="'Retrieve List'">
        <status-codes-retrieve-list />
      </tab>
    </tabs>
  </section>
</template>
<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import StatusCodesCreate from './StatusCodesCreate.md'
import StatusCodesRetrieve from './StatusCodesRetrieve.md'
import StatusCodesRetrieveList from './StatusCodesRetrieveList.md'

export default {
  name: 'StatusCodesPage',
  components: {
    Tabs,
    Tab,
    StatusCodesCreate,
    StatusCodesRetrieve,
    StatusCodesRetrieveList
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
