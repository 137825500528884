<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Retrieve List'" :selected="true">
        <inventory-retrieve-list />
        <box-wrapper :isSample="true" title="Sample Code">
          <br>
          <tabs ref="childTab" @onChangeTab="onChangeChildTab">
            <tab :name="'C#'" :selected="true">
              <inventory-sample-code />
            </tab>
          </tabs>
        </box-wrapper>
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import InventoryRetrieveList from './InventoryRetrieveList.md'
import InventorySampleCode from './InventorySampleCode.md'
import BoxWrapper from '@/components/shared/BoxWrapper.vue'

export default {
  name: 'InventoryPage',
  components: {
    Tabs,
    Tab,
    BoxWrapper,
    InventoryRetrieveList,
    InventorySampleCode
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    },
    onChangeChildTab () {
      this.$refs.childTab.changeTab()
    }
  }
}
</script>
