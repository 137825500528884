<template>
  <section class="content">
    <p>
      Here you will find the information you need to integrate your applications and access your data included in the detailed API documentation, sample code, detailed data dictionaries for our Reporting Cloud and OData instructions.
    </p>
  </section>
</template>

<script>
export default {
  name: 'ResourcePage'
}
</script>
