<template>
  <div class="box">
    <h2 v-if="!isSample">{{title}}</h2>
    <h3 v-else>{{title}}</h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BoxWrapper',
  props: {
    title: {
      type: String
    },
    isSample: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
