<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Create'" :selected="true">
        <shipping-methods-create />
      </tab>
      <tab :name="'Retrieve'">
        <shipping-methods-retrieve />
      </tab>
      <tab :name="'Retrieve List'">
        <shipping-methods-retrieve-list />
      </tab>
    </tabs>
  </section>
</template>
<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import ShippingMethodsCreate from './ShippingMethodsCreate.md'
import ShippingMethodsRetrieve from './ShippingMethodsRetrieve.md'
import ShippingMethodsRetrieveList from './ShippingMethodsRetrieveList.md'

export default {
  name: 'ShippingMethodsPage',
  components: {
    Tabs,
    Tab,
    ShippingMethodsCreate,
    ShippingMethodsRetrieve,
    ShippingMethodsRetrieveList
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
