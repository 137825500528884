<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Create'" :selected="true">
        <locations-create />
      </tab>
      <tab :name="'Retrieve'">
        <locations-retrieve />
      </tab>
      <tab :name="'Retrieve List'">
        <locations-retrieve-list />
      </tab>
      <tab :name="'Update'">
        <locations-update />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import LocationsCreate from './LocationsCreate.md'
import LocationsRetrieve from './LocationsRetrieve.md'
import LocationsRetrieveList from './LocationsRetrieveList.md'
import LocationsUpdate from './LocationsUpdate.md'

export default {
  name: 'LocationsPage',
  components: {
    Tabs,
    Tab,
    LocationsCreate,
    LocationsRetrieve,
    LocationsRetrieveList,
    LocationsUpdate
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
