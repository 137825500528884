<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Create'" :selected="true">
        <certifications-create />
      </tab>
      <tab :name="'Retrieve'">
        <certifications-retrieve />
      </tab>
      <tab :name="'Retrieve List'">
        <certifications-retrieve-list />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import CertificationsCreate from './CertificationsCreate.md'
import CertificationsRetrieve from './CertificationsRetrieve.md'
import CertificationsRetrieveList from './CertificationsRetrieveList.md'

export default {
  name: 'CertificationsPage',
  components: {
    Tabs,
    Tab,
    CertificationsCreate,
    CertificationsRetrieve,
    CertificationsRetrieveList
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
