<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Get Authorization Token'" :selected="true">
        <auth-request />
        <auth-response />
        <box-wrapper
          title="Sample Code"
        >
          <br>
          <br>
          <tabs @onChangeTab="onChangeChildTab" ref="childTabs">
            <tab :name="'C#'" :selected="true">
              <auth-sample-code />
            </tab>
          </tabs>
        </box-wrapper>
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import BoxWrapper from '@/components/shared/BoxWrapper.vue'
import AuthRequest from './AuthRequest.md'
import AuthResponse from './AuthResponse.md'
import AuthSampleCode from './AuthSampleCode.md'

export default {
  name: 'AuthorizationPage',
  components: {
    Tabs,
    Tab,
    BoxWrapper,
    AuthRequest,
    AuthResponse,
    AuthSampleCode
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    },
    onChangeChildTab () {
      this.$refs.childTabs.changeTab()
    }
  }
}
</script>
