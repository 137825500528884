<template>
  <div>
    <p class="logout" v-if="isLoggedIn">
      {{userName}} |
      <a @click="logout()">
        Logout
      </a>
    </p>
    <header class="toolwatch">
      <article class="logo">
        <div class="header-logo">
          <a href="/">
            <img
              class="-screen"
              :src="require('@/assets/images/logo.png')"
              alt="AlignOps logo"
              height="66"
              width="150">
            <img
              class="-print"
              :src="require('@/assets/images/logo-print.png')"
              alt="AlignOps logo"
              height="66"
              width="150">
          </a>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center m-auto">
          <h2>
            Documentation & Support
          </h2>
        </div>
      </article>
      <bread-crumb v-if="isLoggedIn"/>
    </header>
  </div>
</template>
<script>
import axios from 'axios'
import BreadCrumb from '@/components/shared/BreadCrumb.vue'
import { clearLocalStorage } from '../../lib/util'

export default {
  name: 'TwHeader',
  data () {
    return {
      userName: null
    }
  },
  components: {
    BreadCrumb
  },
  created () {
    this.setUserName()
  },
  updated () {
    this.setUserName()
  },
  methods: {
    async logout () {
      try {
        const { status } = await axios.get('/api/logout')
        if (status == 200) {
          clearLocalStorage()
          window.location.href = '/login'
        }
      } catch (error) {
        console.log(error)
      }
    },
    setUserName () {
      this.userName = this.$store.getters.email
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    }
  }
}
</script>
<style scoped>
.logout {
  padding: 12px 24px;
  margin: 0;
  text-align: right;
  line-height: 1;
  color: #fff;
  background-color: #4C84FC;
}
.logout a {
  text-decoration: underline!important;
}
.logout a:hover {
  cursor: pointer;
  color: #545454!important;
}
@media print {
  .logout {
      display: none
  }
}
.toolwatch article.logo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  background-image: linear-gradient(135deg,rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 100%), url('~@/assets/images/ToolWatch-in-action.jpg');
  background-size: cover;
  min-height: 400px;
}

@media (min-width: 1280px) {
  .toolwatch article.logo {
    min-height: 500px;
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: flex-start;
    background-image: linear-gradient(135deg,rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 100%), url('~@/assets/images/ToolWatch-in-action.jpg');
  }
}
.toolwatch img {
  height: auto;
  max-width: 100%;
}

.toolwatch .logo h2 {
  font-size: 30px;
  font-weight: bold;
  color: white;
  text-align: center;
}
.toolwatch .logo p.caption {
  font-size: .8em;
  font-weight: 700;
  margin: 0;
  position: relative;
  text-transform: uppercase;
  color: white;
}
/* .toolwatch .logo p.caption::after {
  content: "";
  position: relative;
  top: auto;
  left: auto;
  border: 0;
  border-radius: 0;
  width: 100%;
  max-width: 60px;
  height: 3px;
  background: #ecb84f;
  display: block;
  margin: 0.6em auto 0.8em;
} */

@media (min-width: 1280px) {
  .toolwatch .logo h2 {
    font-size: 55px;
  }
  .toolwatch .logo p.caption {
    font-size: .9em;
  }
}

@media print {
  .-screen {
      display: none
  }
}
.-print {
  display: none
}
@media print {
  .-print {
      display: initial
  }
}

@media print {
  .toolwatch article:first-child {
      padding: unset;
      background: unset
  }
}

@media print and (min-width: 1280px) {
  .toolwatch article:first-child {
      box-sizing: border-box;
      min-height: unset;
      display: unset;
      padding: unset;
      flex-direction: unset;
      justify-content: unset;
      background-image: unset
  }
}

</style>
