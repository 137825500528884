<template>
  <client-library-code />
</template>

<script>
import ClientLibraryCode from './ClientLibrary.md'

export default {
  name: 'ClientLibrary',
  components: {
    ClientLibraryCode
  },
  mounted () {
    this.$store.commit('setInitialLoading', true)
  }
}
</script>
