<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Create'" :selected="true">
        <descriptions-create />
      </tab>
      <tab :name="'Retrieve'">
        <descriptions-retrieve />
      </tab>
      <tab :name="'Retrieve List'">
        <descriptions-retrieve-list />
      </tab>
      <tab :name="'Update'">
        <descriptions-update />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import DescriptionsCreate from './DescriptionsCreate.md'
import DescriptionsRetrieve from './DescriptionsRetrieve.md'
import DescriptionsRetrieveList from './DescriptionsRetrieveList.md'
import DescriptionsUpdate from './DescriptionsUpdate.md'

export default {
  name: 'DescriptionsPage',
  components: {
    Tabs,
    Tab,
    DescriptionsCreate,
    DescriptionsRetrieve,
    DescriptionsRetrieveList,
    DescriptionsUpdate
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
