<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Create'" :selected="true">
        <category-create />
      </tab>
      <tab :name="'Retrieve'">
        <category-retrieve />
      </tab>
      <tab :name="'Retrieve List'">
        <category-retrieve-list />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import CategoryCreate from './CategoryCreate.md'
import CategoryRetrieve from './CategoryRetrieve.md'
import CategoryRetrieveList from './CategoryRetrieveList.md'

export default {
  name: 'CategoriesPage',
  components: {
    Tabs,
    Tab,
    CategoryCreate,
    CategoryRetrieve,
    CategoryRetrieveList
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
