<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Export'" :selected="true">
        <billing-with-rates-request />
        <billing-with-rates-response />
        <billing-with-rates-data-table />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import BillingWithRatesRequest from './BillingWithRatesRequest.md'
import BillingWithRatesResponse from './BillingWithRatesResponse.md'
import BillingWithRatesDataTable from './BillingWithRatesDataTable.md'

export default {
  name: 'BillingWithRatesPage',
  components: {
    Tabs,
    Tab,
    BillingWithRatesRequest,
    BillingWithRatesResponse,
    BillingWithRatesDataTable
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
