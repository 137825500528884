<template>
  <div class="tabs-component">
    <ul
      class="tabs-component-tabs"
      role="tablist"
    >
      <li
        v-for="(tab, idx) in tabs"
        class="tabs-component-tab"
        :class="{'is-active': tab.isActive, 'show': tab.isActive}"
        :key="tab.id"
      >
        <a class="tabs-component-tab-a"
          data-toggle="tab"
          :href="tab.href"
          :id="tab.id"
          :class="{'show': tab.isActive}"
          @click="selectTab(tab, idx)" >
          {{ tab.name }}
        </a>
      </li>
    </ul>
    <div class="tabs-component-panels">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  data () {
    return {
      tabs: [],
      selectedTab: String
    }
  },
  created () {
    this.tabs = this.$children
  },
  methods: {
    selectFirstTab () {
      this.selectTab(this.tabs[0])
    },
    selectTab (selectedTab, idx) {
      this.selectedTab = selectedTab
      this.selectedTab.$emit('selected')
      this.$emit('onChangeTab', this.selectedTab, idx)
    },
    changeTab () {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name == this.selectedTab.name)
      })
    }
  }
}
</script>
<style scoped>
.tabs-component-tabs {
  margin: 0;
  padding: 0;
  border-bottom: solid 1px #CCC;
  list-style: none;
  display: flex;
  height: 43px;
  overflow-x: auto;
}
@media (min-width: 768px) {
  .tabs-component-tabs {
    overflow-x: unset;
  }
}
.tabs-component .tabs-component-tabs li {
  float: left;
  position: relative;
  top: 2px;
  margin: 0 0.2em 1px 0;
  border-bottom: 0 !important;
  padding: 0;
  white-space: nowrap;
  border: 1px solid #CCC;
  background: #F5F5F5;
  font-weight: normal;
  color: #555555;
}
.tabs-component .tabs-component-tabs li.is-active {
  margin-bottom: 0;
  border: 1px solid #CCC;
  background: #ffffff;
  font-weight: normal;
  color: #212121;
}
.tabs-component .tabs-component-tabs li:not(.is-active):hover {
  background: #F4F7F8;
}
.tabs-component-tabs li a {
  float: left;
  padding: 0.5em 1em;
  color: #212121;
  text-decoration: none!important;
}
.tabs-component .tabs-component-tabs li.is-active a {
  cursor: text;
}
.tabs-component .tabs-component-panels {
  display: block;
  border-width: 0;
  padding: 1em 1.4em;
  background: none;
}
</style>
