<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Retrieve'" :selected="true">
        <work-orders-retrieve />
      </tab>
    </tabs>
  </section>
</template>
<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import WorkOrdersRetrieve from './WorkOrdersRetrieve.md'

export default {
  name: 'WorkOrdersPage',
  components: {
    Tabs,
    Tab,
    WorkOrdersRetrieve
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
