import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import VueBreadCrumbs from 'vue-breadcrumbs'
import routes from './routes'
import { isAuthenticated } from '../lib/util'

Vue.use(VueRouter)
Vue.use(VueBreadCrumbs)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!from.name) {
    store.commit('setInitialLoading', true)
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (await isAuthenticated()) {
      next()
    } else {
      const pathName = window.location.pathname
      const url = `/login?redirect_url=${pathName}`
      await router.push(url)
    }
    store.commit('setInitialLoading', false)
  } else {
    store.commit('setInitialLoading', false)
    next()
  }
})

router.onError(error => {
  if (/loading chunk .* failed./i.test(error.message)) {
    store.commit('setRequiresReload', true)
  }
})

export default router
