<template>
  <section class="content">
    <main class="tw-page-main tw--beige">
      <div class="heading-title-wrapper">
        <h2 class="heading-title">Here When You Need Us</h2>
        <p class="description">The Customer Support team is on call every business day to respond to your inquiries quickly. Complete the form and one of our industry‐leading support professionals will follow up to help resolve your issue rapidly and completely.</p>
      </div>
      <div class="contact-wrapper">
        <div class="contact-info">
          <h3>Email</h3>
          <p>
            <strong>
              Contact Form
            </strong>
            Fill out the form to give us a brief description of your question or concern
          </p>
          <br/>
        </div>
        <div class="contact-info">
          <h3>Web-Based</h3>
          <p>
            <strong>
              Zoom
            </strong>
            Call or email to set up a Zoom meeting to resolve issues.
          </p>
          <br/>
        </div>
        <div class="contact-info">
          <h3>Call</h3>
          <p>
            <strong>
              <a href="tel:+18005803114" data-gc-link="tel:+18005803114">800.580.3114</a>
            </strong>
            Phone support is available Monday–Friday, 6 AM–5 PM Mountain Time (excluding holidays).
          </p>
          <br/>
        </div>
      </div>
      <div class="tw-inner">
        <h2>Support Contact Form</h2>
        <p>
          Please complete the form below and a support representative will respond to your inquiry as soon as possible.
        </p>
        <div class="tw-column-container">
          <div class="tw-column-root">
            <form
              class="tw-form-root"
              id="support"
              name="support"
              method="post"
              action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
              @submit="validateForm"
            >
              <div class="tw-form-column-container">
                <div class="tw-form-column">
                  <div class="tw-form-field">
                    <form-group
                      :validator="$v.firstName"
                      attribute="First name"
                    >
                      <label class="control-label" for="first-name">
                        First name*
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="first-name"
                        class="tw-form-input form-control"
                        :class="{ error: $v.firstName.$invalid }"
                        maxlength="80"
                        v-model="firstName"
                        @input="$v.firstName.$touch()"
                        inputmode="latin-name"
                        autocomplete="name"
                      >
                    </form-group>
                  </div>
                  <div class="tw-form-field">
                    <form-group
                      :validator="$v.lastName"
                      :attribute="'Last name'"
                    >
                      <label class="control-label" for="last-name">
                        Last name*
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="last-name"
                        class="tw-form-input form-control"
                        :class="{ error: $v.lastName.$invalid }"
                        maxlength="80"
                        v-model="lastName"
                        @input="$v.lastName.$touch()"
                        inputmode="latin-name"
                        autocomplete="name"
                      >
                    </form-group>
                  </div>
                  <div class="tw-form-field">
                    <form-group
                      :validator="$v.email"
                      :attribute="'Email'"
                    >
                      <label class="control-label" for="support-email">
                        Email*
                      </label>
                      <input
                        type="text"
                        name="email"
                        id="support-email"
                        class="tw-form-input form-control"
                        :class="{ error: $v.email.$invalid }"
                        maxlength="80"
                        v-model="email"
                        @input="$v.email.$touch()"
                        autocomplete="work email"
                      >
                    </form-group>
                  </div>
                  <div class="tw-form-field">
                    <label class="control-label" for="support-phone">
                      Phone number
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      id="support-phone"
                      class="tw-form-input form-control"
                      maxlength="40"
                      pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
                      autocomplete="work tel">
                  </div>
                  <div class="tw-form-field">
                    <form-group
                      :validator="$v.subject"
                      :attribute="'Subject'"
                    >
                      <label class="control-label" for="support-subject">
                        Subject*
                      </label>
                      <input
                        type="text"
                        name="subject"
                        id="support-subject"
                        class="tw-form-input form-control"
                        maxlength="80"
                        v-model="subject"
                        @input="$v.subject.$touch()"
                        :class="{ error: $v.subject.$invalid }">
                    </form-group>
                  </div>
                  <div class="tw-form-field filter-field tw-select-field">
                    <form-group
                      :validator="$v.description"
                      :attribute="'What best describes the nature of your issue?'"
                    >
                      <label class="control-label" for="sales-severity">
                        What best describes the nature of your issue?*
                      </label>
                      <textarea name="description"
                                id="sales-severity"
                                class="tw-form-input form-control"
                                rows="3"
                                v-model="description"
                                @input="$v.description.$touch()"
                                :class="{ error: $v.description.$invalid }">
                      </textarea>
                    </form-group>
                  </div>
                  <div class="tw-form-field">
                    <label class="control-label" for="00N2M00000fzjeN">
                      Message
                    </label>
                    <textarea name="00N2M00000fzjeN" id="00N2M00000fzjeN" class="tw-form-input form-control" rows="3"></textarea>
                  </div>
                  <input name="orgid" type="hidden" value="00D41000000fTiP">
                  <input id="recordType" name="recordType" type="hidden" value="01241000001AWQA">
                  <input id="OwnerId" name="OwnerId" type="hidden" value="00G2M000002h8Zn">
                  <input id="reason" name="reason" type="hidden" value="New problem">
                  <input id="type" name="type" type="hidden" value="Problem">
                  <input id="00N2M00000fr9yE" name="00N2M00000fr9yE" type="hidden" value="ToolWatch">
                  <input type="hidden" name="retURL" value="https://help.toolwatch.com/support/thanks">
                </div>
              </div>
              <p>
                By submitting this form, you consent to receive communications from us regarding your submission.
              </p>
              <div class="tw-form-tray">
                <input
                  type="submit"
                  :value="'Submit'"
                  name="send"
                  id="support-send"
                  class="tw-form-button">
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'SupportPage',
  data () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      description: '',
      subject: ''
    }
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    email: {
      required,
      email
    },
    description: {
      required
    },
    subject: {
      required
    }
  },
  created () {
    this.firstName = this.$store.getters.firstName
    this.lastName = this.$store.getters.lastName
    this.email = this.$store.getters.email
  },
  methods: {
    validateForm (e) {
      if (!this.$v.$invalid) return true
      e.preventDefault()
    }
  }
}
</script>
<style scoped>
.tw-page-breadcrumb {
  margin-bottom: 0.5em;
  font-weight: 900;
  font-size: 1.5em;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #4C84FC;
}
.tw-form-root .tw-form-column {
  margin-bottom: 40px;
}
.tw-form-root .tw-form-field {
  margin-bottom: 10px;
}
.tw-form-field label {
  color: #495760;
  font-weight: bold;
}
.tw-form-field select {
  background-color: white;
}
.tw-form-field input,
.tw-form-field select {
  height: 36.5px;
}
.tw-form-tray .tw-form-hint {
  font-size: 0.9em;
  margin-left: 5px;
}
.tw-column-root .tw-form-input {
  width: 100%;
  max-width: none;
}
.tw-button-root, .tw-form-button {
  --tw-button-foreground: #4C84FC;
  --tw-button-background: #fff;
  --tw-button-border: #4C84FC;
  display: inline-block;
  padding: 4px 20px;
  font-weight: 700;
  color: var(--tw-button-foreground)!important;
  background-color: var(--tw-button-background)!important;
  border: 1px solid var(--tw-button-border)!important;
}
.tw-form-button:hover {
  text-decoration: none;
  --tw-button-foreground: #fff;
  --tw-button-background: #4C84FC;
  --tw-button-border: #4C84FC;
}
.contact-info h3 {
  font-style: unset;
}
.contact-info strong,
.contact-info strong a {
  display: block;
  color: #364750;
}
.contact-info strong a {
  text-decoration: unset;
}
</style>
