<template>
  <section class="content">
    <tabs @onChangeTab="onChangeTab" ref="tabs">
      <tab :name="'Retrieve List'" :selected="true">
        <safety-trainers-retrieve-list />
      </tab>
    </tabs>
  </section>
</template>

<script>
import Tab from '@/components/shared/Tab.vue'
import Tabs from '@/components/shared/Tabs.vue'
import SafetyTrainersRetrieveList from './SafetyTrainersRetrieveList.md'

export default {
  name: 'SafetyTrainersPage',
  components: {
    Tabs,
    Tab,
    SafetyTrainersRetrieveList
  },
  methods: {
    onChangeTab () {
      this.$refs.tabs.changeTab()
    }
  }
}
</script>
