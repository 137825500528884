<template>
  <nav class="-js" :style="[isOpen ? { maxHeight: navHeight + 'px' } : {}]" ref="nav">
    <button class="menu_button" :class="{ '-active': isOpen }" @click="openNav">
      {{!isOpen ? 'Open Navigation' : 'Close Navigation'}}
    </button>
    <template v-for="(nav, index) in navigations">
      <template v-if="isRoute">
        <h2 :key="`header_${index}`">{{nav.groupName ? nav.groupName : nav.group}}</h2>
        <ul :key="`nav_${index}`">
          <li v-for="(item, id) in nav.items" :key="`item_${id}`">
            <template v-if="!item.isExternal">
              <router-link
                :to="{ name: item.linkRoute }"
              >{{item.routeName || item.name}}
              <img v-if="isNewItem(item)" src="@/assets/images/new.png" height="24" width="24"/>
              </router-link>
            </template>
            <template v-else>
              <a :href="item.path" target="blank">{{item.routeName || item.name}}</a>
            </template>
          </li>
        </ul>
      </template>
      <template v-else>
        <h1 :key="`header_${index}`" :class="{'first-child': !index}">
          <a :href="`#${nav.link}`">
            {{nav.groupName ? nav.groupName : nav.group}}
          </a>
        </h1>
        <ul :key="`nav_${index}`">
          <li
            v-for="(item, id) in nav.items"
            :key="`item_${id}`"
            :class="{'no-list-style': Array.isArray(item)}"
          >
            <template v-if="!Array.isArray(item)">
              <a :href="`#${item.linkRoute}`">
                {{item.routeName || item.name}}
              </a>
            </template>
            <template v-else>
              <template v-for="(ele, eleIdx) in item">
                <h2 :key="`nested_${index}_${eleIdx}`" class="mt-2">
                  <a :href="`#${ele.link}`">
                    {{ele.group}}
                  </a>
                </h2>
                <ul :key="`ele_${index}_${eleIdx}`">
                  <li
                    v-for="(eleItem, eleItemIndex) in ele.items"
                    :key="`eleItem_${eleItemIndex}`"
                  >
                    <a :href="`#${eleItem.linkRoute}`">
                      {{eleItem.routeName || eleItem.name}}
                    </a>
                  </li>
                </ul>
              </template>
            </template>
          </li>
        </ul>
      </template>
    </template>
  </nav>
</template>

<script>
export default {
  name: 'TwSidebar',
  data () {
    return {
      isOpen: false,
      navHeight: 0
    }
  },
  props: {
    navigations: {
      type: Array
    },
    isRoute: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    openNav () {
      this.isOpen = !this.isOpen
      this.navHeight = this.$refs.nav.scrollHeight
    },
    isNewItem (item) {
      if (item.props) {
        if (item.props.isNew) {
          return item.props.isNew
        }
      }
      return false
    }
  },
  watch: {
    '$route.path': {
      handler: function () {
        this.isOpen = false
      }
    }
  }
}
</script>
<style scoped>
nav {
  padding: 20px;
  background-color: #F4F7F8;
}
nav.-js {
  max-height: calc(2em + 45px);
  overflow: hidden;
  transition-property: max-height;
  transition-duration: .5s;
}
nav .menu_button {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #4C84FC;
  border-radius: 0.25rem;
  margin-bottom: 1.5em;
  font: inherit;
  font-weight: bold;
  line-height: 2;
  color: #fff;
  background-color: #4C84FC;
}
nav ul {
  margin-left: 1em;
  list-style: square;
}
nav ul > li {
  padding: 5px 0 5px 0;
}
nav * a {
  color: #323232;
}
nav * a:hover {
  color: #767877;
}
nav :link, nav:visited {
  text-decoration: none;
}
nav h1 {
  font-weight: 900;
  margin-top: 40px;
  font-size: 16px;
}
nav h2 {
  font-weight: bold;
}
nav h1.first-child {
  margin-top: 0;
}
nav .menu_button.-active {
  color: #4C84FC;
  background-color: transparent;
}
li.no-list-style {
  list-style: none;
}
@media (min-width: 1280px) {
  nav {
    float: left;
    width: 300px;
    height: 100vh;
    overflow-y: auto;
    position: sticky;
    top: 0;
    padding: 48px;
    box-sizing: border-box;
  }
  nav.-js {
    max-height: unset;
    overflow-y: auto;
  }
  nav .menu_button {
    display: none;
  }
}
</style>
